import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";

function handleClickSetLocalStorageJR () {
	localStorage.setItem('JR', 'hello');
}

function handleClickRemoveLocalStorageJR () {
	localStorage.removeItem('JR');
}

const DevOnlyPage = () => {
	const [isGetInstalledRelatedApp, setIsGetInstalledRelatedApp] = useState("false");
	const [installedRelatedApps, setInstalledRelatedApps] = useState("n/a");
	const [isWindowNavigator, setIsWindowNavigator] = useState("false");
	const [isWindowNavigatorStandalone, setIsWindowNavigatorStandalone] = useState("false");
	const [isDisplayModeStandalone, setIsDisplayModeStandalone] = useState("false");
	const [isServiceWorker, setIsServiceWorker] = useState("false");
	const [isStoragePersisted, setIsStoragePersisted] = useState("false");
	const [responseRequestPermission, setResponseRequestPermission] = useState(undefined);
	const [statusNotificationPermission, setStatusNotificationPermission] = useState("n/a");
	const titleNotification = 'Hello!';

	const [localStorageJR, setLocalStorageJR] = useState(null);

	const handleClickGetLocalStorage = () => setLocalStorageJR(JSON.stringify(localStorage.getItem('JR')));

	const handleClickGetInstalledRelatedApps = async () => {
		if(!window.navigator?.getInstalledRelatedApps) return;
		const aInstalledApps = await navigator.getInstalledRelatedApps();
		if(Array.isArray(aInstalledApps)){
			setInstalledRelatedApps(JSON.stringify(aInstalledApps));
		}
	};

	const handleClickCheckLocalStoragePersisted = async () => {
		if(!window.navigator?.storage?.persisted) return;
		const isPersisted = await window.navigator.storage.persisted();
		setIsStoragePersisted(isPersisted ? "true" : "false");
	};

	const handleClickAskLocalStoragePersist = async () => {
		if(!window.navigator?.storage?.persist) return;
		const isPersisted = await window.navigator.storage.persist();
		console.log(`The persist() method resolved to: ${isPersisted}`);
	};

	const handleClickAskNotificationPermission = async () => {
		if(!window.Notification?.requestPermission) return;
		const response = await Notification.requestPermission();
		setResponseRequestPermission(response);
	};

	const handleClickCheckStatusNotificationPermission = () => {
		if(!window.Notification?.permission) return;
		setStatusNotificationPermission(window.Notification.permission);
	};

	const handleClickCreateWindowNotification = async () => {
		if(!window.Notification?.permission === "granted") return;
		if(!window.navigator?.serviceWorker) return;
		else console.log(`window.Notification.permission: ${window.Notification.permission}`);
		const optionsNotification = {
			body: 'This is the body',
			requireInteraction: true,
			timestamp: Date.now()
		};
		const sw = await window.navigator.serviceWorker.ready;
		console.log(sw.showNotification(titleNotification, optionsNotification));
	}

	const handleClickCheckWindowNavigator = () => setIsWindowNavigator(JSON.stringify(typeof window !== "undefined" && typeof window.navigator == 'object'));
	const handleClickGetInstalledRelatedApp = () => setIsGetInstalledRelatedApp(window.navigator?.getInstalledRelatedApps ? "true" : "false");
	const handleClickCheckNavigatorStandalone = () => setIsWindowNavigatorStandalone(window.navigator?.standalone ? "true" : "false");
	const handleClickCheckDisplayModeStandalone = () => setIsDisplayModeStandalone(window.matchMedia('(display-mode: standalone)').matches ? "true" : "false");
	const handleClickCheckServiceWorker = () => setIsServiceWorker(window.navigator?.serviceWorker ? "true" : "false");

	return (
		<Layout>
			<h2>DevOnly</h2>

			<div className="main--centerwrapper">
				<StaticImage
				src="../images/photos/pressRes/Jesse-high-5.jpg"
				alt="Jesse Rivest press image"
				placeholder="blurred"
				layout="constrained"
				className="main--centered"
				imgClassName="img--bordered-small"
				width={500}
				/>
			</div>
			<h3>DevOnly</h3>
			<p>
				This is a temporary development/debugging area.
				If you are seeing this, you are very crafty and perceptive.
				Congrats.
				Now, go check out my music.
				Please 😎.
			</p>

			<h4>Is PWA installed?</h4>
			<ul>
				<li>
					<button onClick={handleClickCheckWindowNavigator}>check</button>&nbsp;
					window.navigator: {isWindowNavigator}
				</li>

				{/* https://web.dev/articles/get-installed-related-apps PLUS look at MDN */}
				<li>
					<button onClick={handleClickGetInstalledRelatedApp}>check</button>&nbsp;
					window.navigator.getInstalledRelatedApps: {isGetInstalledRelatedApp}
				</li>
				<li>
					<button onClick={handleClickGetInstalledRelatedApps}>getInstalledRelatedApps()</button>&nbsp;
					<textarea value={installedRelatedApps} readOnly></textarea>
				</li>

				<li>
					<button onClick={handleClickCheckNavigatorStandalone}>check</button>&nbsp;
					window.navigator.standalone: {isWindowNavigatorStandalone}
				</li>
				<li>
					<button onClick={handleClickCheckDisplayModeStandalone}>check</button>&nbsp;
					window.matchMedia('(display-mode: standalone)').matches: {isDisplayModeStandalone}
				</li>
				<li>
				<button onClick={handleClickCheckServiceWorker}>check</button>&nbsp;
					window.navigator.serviceWorker: {isServiceWorker}
				</li>
			</ul>

			<h4>Localstorage</h4>
			<ul>
				<li>
					<button onClick={handleClickGetLocalStorage}>check</button>&nbsp;
					localStorage.getItem('JR'): {localStorageJR}
				</li>
				<li><button onClick={handleClickSetLocalStorageJR}>localStorage.setItem('JR')</button></li>
				<li><button onClick={handleClickRemoveLocalStorageJR}>localStorage.removeItem('JR')</button></li>
				<li>
					<button onClick={handleClickCheckLocalStoragePersisted}>check</button>&nbsp;
					window.navigator.storage.persisted(): {isStoragePersisted}
				</li>
				<li>
					<button onClick={handleClickAskLocalStoragePersist}>ask</button>&nbsp;
					window.navigator.storage.persist()
				</li>
			</ul>

			<h4>Notifications</h4>
			<ul>
				<li>
					<button onClick={handleClickAskNotificationPermission}>ask</button>&nbsp;
					window.Notification.requestPermission(): {responseRequestPermission}
				</li>
				<li>
					<button onClick={handleClickCheckStatusNotificationPermission}>check</button>&nbsp;
					window.Notification.permission: {statusNotificationPermission}
				</li>
				<li>
					<button onClick={handleClickCreateWindowNotification}>create</button>&nbsp;
					new window.Notification({titleNotification})
				</li>
			</ul>
		</Layout>
	);
}

export default DevOnlyPage;
